import { NewRowEntry } from "types/configManager";

// Function to verify and encode template only for field definition as Template.
export const verifyAndEncodeTemplate = (newConfig: NewRowEntry): NewRowEntry => {
  newConfig.results?.forEach((result) => {
    if (result.name === 'Template' && result.value) {
      result.value = encodeData(result.value);
    }
  });
  return newConfig;
};


// Function to verify and decode template only for field definition as Template.
export const verifyAndDecodeTemplate = (newConfig: NewRowEntry): NewRowEntry => {
  newConfig.results?.forEach((result) => {
    if (result.name === 'Template' && result.value) {
      result.value = decodeData(result.value);
    }
  });
  return newConfig;
};

// Function to encode data (e.g., Base64)
export const encodeData = (data: string): string | null => {
  try {
    return btoa(JSON.stringify(data));
  } catch (error) {
    console.error("Error encoding data:", error);
    return null;
  }
};

// Function to decode data (e.g., Base64)
export const decodeData = (data: string): string | null => {
  try {
    data = JSON.parse(atob(data)).trim();
    return data;
  } catch (error) {
    console.error("Error decoding data:", error);
    return null;
  }
};